<template>
    <el-container :class="[isCollapse ? 'el-aside-hide' : 'el-aside-show']">
        <!--左侧菜单-->
        <el-aside>
            <el-menu
                mode="vertical"
                background-color="#304156"
                text-color="#bfcbd9"
                active-text-color="#409EFF"
                :unique-opened="true"
                :collapse-transition="false"
                :default-active="this.$router.path"
                router
            >
                <el-submenu
                    v-for="item in menuList"
                    :key="item.id"
                    :index="'/' + item.url"
                    @click="menuClick(item.name)"
                >
                    <template slot="title">
                        <span slot="title">{{ item.name }}</span>
                    </template>

                    <el-menu-item
                        v-for="subItem in item.children"
                        :key="subItem.id"
                        :index="'/' + item.url + '/' + subItem.url"
                        :route="'/' + item.url + '/' + subItem.url"
                        @click="menuClick(item.name, subItem.name)"
                    >
                        <span slot="title">{{ subItem.name }}</span>
                    </el-menu-item>
                </el-submenu>
            </el-menu>
        </el-aside>

        <!--内容区域-->
        <el-main>
            <router-view />
        </el-main>

        <!--头部导航-->
        <el-header>
            <div class="app-header-breadcrumb">
                <!--显示或隐藏左侧菜单-->
                <div class="app-header-collapse">
                    <i
                        v-show="!isCollapse"
                        class="el-icon-s-fold"
                        @click="isCollapse = !isCollapse"
                    ></i>
                    <i
                        v-show="isCollapse"
                        class="el-icon-s-unfold"
                        @click="isCollapse = !isCollapse"
                    ></i>
                </div>

                <!--面包屑导航-->
                <el-breadcrumb separator-class="el-icon-arrow-right">
                    <el-breadcrumb-item
                        v-for="(item, index) in menuCurrent"
                        :key="index"
                    >
                        {{ item }}
                    </el-breadcrumb-item>
                </el-breadcrumb>

                <!--右侧按钮-->
                <div class="app-header-btn">
                    <h3 class="wrap">
                        <span class="scroll-title">{{
                            userInfo.userName
                        }}</span>
                        <span class="scroll-title">{{ userInfo.name }}</span>
                    </h3>
                    <h3>，欢迎使用！</h3>
                    <el-link type="info" @click="myAccount">账号信息</el-link>
                    <el-link type="danger" @click="passwordDialog = true">
                        密码管理
                    </el-link>
                    <el-link type="warning" @click.native="logout">
                        安全退出
                    </el-link>
                </div>
            </div>
        </el-header>

        <!--密码管理-->
        <el-dialog
            title="密码管理"
            :visible.sync="passwordDialog"
            width="400px"
            :modal-append-to-body="false"
            :close-on-click-modal="false"
        >
            <el-form
                :model="passwordFields"
                :rules="passwordRules"
                ref="passwordForm"
                label-width="70px"
            >
                <el-form-item label="新密码" prop="newPassword">
                    <el-input
                        type="password"
                        show-password
                        v-model="passwordFields.newPassword"
                    ></el-input>
                </el-form-item>
                <el-form-item style="margin: 0">
                    <el-button type="primary" @click="passwordSubmit">
                        提交
                    </el-button>
                </el-form-item>
            </el-form>
        </el-dialog>
    </el-container>
</template>

<script>
export default {
    data() {
        const passwordRule = (rule, value, callback) => {
            // 必须包含数字/小写字母/大写字母/特殊字符
            const pattern =
                /^.*(?=.*\d)(?=.*[A-Z])(?=.*[a-z])(?=.*[!@#$%^&*-?]).*$/
            // 只能由数字或小写字母或大写字母组成
            // const pattern = /^.*(?=.*\d)|(?=.*[A-Z])|(?=.*[a-z]).*$/
            if (!pattern.test(value)) {
                callback(new Error('必须包含数字/小写字母/大写字母/特殊字符'))
            } else {
                callback()
            }
        }
        return {
            isCollapse: false, //左侧菜单展开折叠
            menuList: [], //左侧菜单
            menuCurrent: [], //当前菜单
            userInfo: null, //用户信息
            passwordDialog: false,
            passwordFields: {
                newPassword: '',
            },
            passwordRules: {
                newPassword: [
                    {
                        required: true,
                        message: '必填',
                        trigger: 'blur',
                    },
                    {
                        min: 6,
                        max: 16,
                        message: '长度在6到16个字符',
                        trigger: 'blur',
                    },
                    {
                        validator: passwordRule,
                        message:
                            '必须包含数字/小写字母/大写字母/特殊字符(!@#$%^&*-?)',
                        trigger: 'blur',
                    },
                ],
            },
        }
    },
    created() {
        this.userInfo = this.$store.state.user.userInfo
        this.menuList = this.$store.state.user.menuList
        this.menuCurrent = this.$store.state.user.menuCurrent
    },
    methods: {
        logout() {
            this.$confirm('您确定要退出登录吗', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
            }).then(() => {
                this.$message({
                    duration: 1000,
                    type: 'success',
                    message: '成功退出登录',
                    onClose: async () => {
                        await this.$api.logout()
                        this.$store.commit('logout')
                        this.$router.push('/login')
                    },
                })
            })
        },
        passwordSubmit() {
            this.passwordFields.id = this.userInfo.id
            this.$refs['passwordForm'].validate((valid) => {
                if (valid) {
                    this.$api.resetPassword(this.passwordFields).then(() => {
                        this.$message({
                            duration: 1000,
                            type: 'success',
                            message: '密码修改成功,请重新登录',
                            onClose: async () => {
                                this.$store.commit('logout')
                                this.$router.push('/login')
                            },
                        })
                    })
                }
            })
        },
        // 左侧菜单点击
        menuClick(itemName, subItemName = null) {
            this.menuCurrent = []
            this.menuCurrent.push(itemName)
            if (subItemName !== null) {
                this.menuCurrent.push(subItemName)
            }
            sessionStorage.setItem(
                'menuCurrent',
                JSON.stringify(this.menuCurrent)
            )
        },
        // 账号信息
        myAccount() {
            this.menuCurrent = []
            this.menuCurrent.push('账号信息')
            this.$router.push('/')
        },
    },
}
</script>
<style lang="less" scoped>
.wrap {
    position: relative;
    width: 200px;
    overflow: hidden;
    font-weight: 600;
}

.scroll-title {
    display: inline-block;
    white-space: nowrap;
    cursor: pointer;
    font-weight: 600;
}

.scroll-title:hover {
    animation: move 3s infinite alternate linear;
}

@keyframes move {
    0% {
        transform: translate(0, 0);
    }
    100% {
        transform: translate(calc(-100% + 200px), 0);
    }
}
</style>
